import { Initialize } from 'pacto';
import { Paywall as View } from '../views/Paywall';
import { PactoHelper } from '../../../../_utils/PactoHelper';
import { Environment } from '../../../../_utils/Environment';

const SELECTOR = '#piano-static-paywall';

export class Action extends Initialize {
  get settings() {
    return {
      selector: SELECTOR,
      namespace: 'pay:views',
      view: View,
    };
  }

  run() {
    const env = new Environment();
    if ( env.isArticleDetailPage() || env.isPayStandalonePage() ) {
      (new PactoHelper()).waitForElm(SELECTOR).then(() => {
        super.run();
      });
    }
  }
}
