import { View } from 'pacto';
import { ConsoleDebug } from '../../../_utils/ConsoleDebug';
import { Environment } from '../../../_utils/Environment'
import { PactoHelper } from '../../../_utils/PactoHelper';
import { RedfactHelper } from '../../../_utils/RedfactHelper';
import { AccountData } from '../../../core/account/service/AccountData';


const CLASS_HIDDEN = 'paywall-quota--hidden';
const SELECTOR_STATUS = '#paywall-quota-status';
const SELECTOR_AVAILABLE = '#paywall-quota-available';
const SELECTOR_EXHAUSTED = '#paywall-quota-exhausted';
const SELECTOR_BUTTON = '#button-paywall-quota';
const SELECTOR_NAME = '.paywall-quote__accountname';
const ERROR_MESSAGE = 'Es ist ein technisches Problem aufgetreten. Bitte versuchen Sie es später noch einmal. Der Webmaster wurde informiert.';
const CTXT_KEY_EVENT_CHANGED = 'event:account_data_changed';

export class Quota extends View {

  constructor(options) {
    super(options);

    this.env = new Environment();
    this.logInstance = new ConsoleDebug('debug-account', 'Quota');
    this.logInstance.log('common/pay/quota/views/Quota.js constructor');

    this.accountData = new AccountData(this.context);
    this.redfactHelper = new RedfactHelper();

    this.buildError = this.buildError.bind(this);
    this.quota = this.quota.bind(this);
    this.getRemainQuota = this.getRemainQuota.bind(this);
    this.showTextDependOnLoggedIn = this.showTextDependOnLoggedIn.bind(this);
    this.toAnchor = this.toAnchor.bind(this);
  }

  async render() {
    this.logInstance.log('render() called');

    this.el.querySelector(SELECTOR_BUTTON)?.addEventListener('click', this.quota);

    this.showTextDependOnLoggedIn();

    this.context.on(CTXT_KEY_EVENT_CHANGED, async (event) => {
      this.logInstance.log('Accountdaten wurden geändert, wahrscheinlich login. Funktion showTextDependOnLoggedIn() erneut aufrufen.');
      this.showTextDependOnLoggedIn();
    });
  }

  async showTextDependOnLoggedIn () {
    this.logInstance.log('showTextDependOnLoggedIn() called');

    if ( await this.accountData.isLoggedIn() )
    {
      const remainQuota = await this.getRemainQuota();

      // Platzhalter ersetzten durch personalisierte Begrüßung#
      const accountName = await this.accountData.getFirstname() + ' ' + await this.accountData.getLastname();
      const nodeList = this.el.querySelectorAll(SELECTOR_NAME);
      nodeList.forEach(placeholder => {
        const newText = 'Guten Tag ' + accountName + ', ' + placeholder.innerText.toLowerCase();
        this.logInstance.log(`ersetze '${placeholder.innerText}' durch '${newText}'`);
        placeholder.innerText = newText;
      });

      if ( remainQuota > 0 ) {
        this.el.querySelector(SELECTOR_STATUS).innerHTML = (remainQuota-1);
        this.el.querySelector(SELECTOR_AVAILABLE)?.classList.remove(CLASS_HIDDEN);
      } else {
        this.el.querySelector(SELECTOR_EXHAUSTED)?.classList.remove(CLASS_HIDDEN);
      }
      this.el.classList.remove(CLASS_HIDDEN);
    }
  }

  /*
	 * Der redFACT REST Service "payment/quota" schaltet einen Artikel frei, d.h. legt eine redFACT Bestellung mit
	 * dem Produkt "Plus-Artikel Kontingent" an und leitet dann auf sich selbst um
	 * http://www.mannheimer-morgen.de/REST/payment/quota/ar/1205646/1192/1
   */
  quota (e) {
    this.logInstance.log('quota() called');
    const endpoint = e.target.getAttribute('data-endpoint');
    const fetchInit = { method: "GET", credentials: "include" };
    window.fetch(endpoint, fetchInit)
      .then((response) => response.json())
      .then((data) => {
        if ( typeof data.success === "boolean"
          && data.success
          && typeof data.location === "string"
          && data.location !== "" )
        {
          location.href = data.location; // Umleiten auf Artikelseite
        } else {
          this.buildError(null, 'fetch ' + endpoint + ' wasn\'t successful');
        }
      })
      .catch((error) => {
        this.buildError(null, 'fetch ' + endpoint + ' wasn\'t successful. Error: ' + error);
      });
  }

  /*
	 * Der redFACT REST Service "/REST/community/quotastatus" gibt einen int/number zurück, wie viele Plus-Artikel noch freigeschalten werden dürfen
	 *
	 * tfenrich@haas-xmac02 ~ % curl --request GET \
--header 'User-Agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36' \
--cookie 'rfFUS=0192bef70ac57036427c788cd86cdf12' \
"https://xmedias3.mannheimer-morgen.de/REST/community/quotastatus?puid=3"
   *
   */
  async getRemainQuota () {
    this.logInstance.log('getRemainQuota() called');

    let remainQuota = false;

    const endpoint = this.redfactHelper.getRestEndpointQuotastatus();
    await window.fetch(endpoint, { method: "GET", credentials: "include" })
      .then((response) => response.json())
      .then((data) => {
        remainQuota = data.remainQuota;
      })
      .catch((error) => {
        this.buildError(null, 'fetch ' + endpoint + ' wasn\'t successful. Error: ' + error);
      });

    return remainQuota;
  }

  toAnchor () {
    if ( this.el != null ) {
      const rect = this.el.getBoundingClientRect();
      window.scrollTo({top: (document.documentElement.scrollTop + rect.top)});
    }
  }

  buildError(explictMessage = null, explictLogMessage = null) {
    if (explictLogMessage !== null && explictLogMessage !== '') {
      this.logInstance.log('explictLogMessage = ' + explictLogMessage);
    }
    let message = ERROR_MESSAGE;
    if (explictMessage !== null && explictMessage !== '') {
      message = explictMessage;
    }
    var el = this.el.querySelector('.paywall-quota__error');
    if ( el !== null ) {
      this.toAnchor();
      el.innerHTML = message;
      el.classList.remove(CLASS_HIDDEN);
    } else {
      this.logInstance.log('Keine Error-Container gefunden für Fehlermeldung => ' + message);
    }
  }
}
