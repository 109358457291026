import { Initialize } from 'pacto';
import { Quota as View } from '../views/Quota';
import {PactoHelper} from "../../../_utils/PactoHelper";
import { Environment } from '../../../_utils/Environment';

const SELECTOR = '.paywall-quota';

export class Action extends Initialize {
  get settings() {
    return {
      selector: SELECTOR,
      namespace: 'paywall-quota:views',
      view: View,
    };
  }

  run() {
    const env = new Environment();
    if ( env.isArticleDetailPage() || env.isPayStandalonePage() ) {
      (new PactoHelper()).waitForElm(SELECTOR).then(() => {
        super.run();
      });
    }
  }
}
