/*Mouseflow ist ein Webanalyse-Tool das Nutzerverhalten auf einer Website zu analysieren und entsprechende Optimierungen an der User Experience vorzunehmen.
Unser Angebot umfasst Session Replays/Aufzeichnungen, Heatmaps, Funnel- und Formularanalyse, sowie Feedback-Kampagnen*/
import { Environment } from "../../../_utils/Environment";

const body = document.body;
const cmtId = 's2441';
const toolToken = 'tool_mouseflow';
let mouseflowLoaded = false;
let currentMFId = ''

const MM_ID = "4fe03209-01b5-4619-bf04-ef268a3a3f07";
const BA_ID = "5382044a-6e71-4c58-8636-0be5cf10d612";
const SZ_ID = "18c6efe1-4f35-442c-bd2c-887a74baa6fe";
const FN_ID = "dface9a6-5196-47c7-ad49-5f39d746f759";

export class Mouseflow {

  load() {
    this.environment = new Environment();

    let currentPuId = this.environment.getCurrentPuId();
    switch (currentPuId) {
      case 3: currentMFId = MM_ID; break;
      case 5: currentMFId = BA_ID; break;
      case 6: currentMFId = SZ_ID; break;
      case 7: currentMFId = FN_ID; break;
      default: currentMFId = MM_ID;
    }


    window._mfq = window._mfq || [];
    (function() {
      var mf = document.createElement("script");
      mf.type = "text/javascript"; mf.defer = true;
      mf.src = "//cdn.mouseflow.com/projects/"+currentMFId+".js";
      document.getElementsByTagName("head")[0].appendChild(mf);
    })();

    mouseflowLoaded = true;
  }

  destroy(){
    this.destroyCookie('mf_');
    mouseflowLoaded = false;
  }

  getCmtId() {
    return cmtId;
  }

  getToolToken() {
    return toolToken;
  }

  isLoaded() {
    return mouseflowLoaded;
  }
  destroyCookie(cookieName) {
      let domain = document.location.hostname.replace(/^[^.]+/g, "");
      let expires = "expires=Thu, 01 Jan 1970 00:00:00 GMT"
      document.cookie = cookieName + "='';" + expires + ";domain="+domain+";path=/;";
      return true;
  }
}


