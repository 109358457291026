import { Initialize } from 'pacto';
import { Register as View } from '../views/Register';


export class Action extends Initialize {
  get settings() {
    return {
      selector: '.core-register',
      namespace: 'registeraccount:views',
      view: View,
    };
  }
}
