import {Environment} from "../../../_utils/Environment";

const body = document.body;
const cmtId = 's7';
const toolToken = 'tool_facebook_pixel';
let fbPixelLoaded = false;
let currentMetaId = ''

const MM_ID = "609868845818725";
const BA_ID = "992914242423103";
const SZ_ID = "1445493326340521";
const FN_ID = "783127766826213";

export class FacebookPixel {

  load() {

    this.environment = new Environment();

    let currentPuId = this.environment.getCurrentPuId();
    switch (currentPuId) {
      case 3: currentMetaId = MM_ID; break;
      case 5: currentMetaId = BA_ID; break;
      case 6: currentMetaId = SZ_ID; break;
      case 7: currentMetaId = FN_ID; break;
      default: currentMetaId = MM_ID;
    }

    !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', currentMetaId);fbq('track', 'PageView');
    fbPixelLoaded = true;
    let noScriptEl = document.createElement('noscript');
    let imgEl = document.createElement('img');
    imgEl.height = "1";
    imgEl.width = "1";
    imgEl.style.display = "none";
    imgEl.src = "https://www.facebook.com/tr?id="+currentMetaId+"&ev=PageView&noscript=1";
    noScriptEl.append(imgEl);
    document.body.append(noScriptEl);
  }

  destroy(){
    this.destroyCookie('_fbp');
    fbPixelLoaded = false;
  }

  getCmtId() {
    return cmtId;
  }

  getToolToken() {
    return toolToken;
  }

  isLoaded() {
    return fbPixelLoaded;
  }

  destroyCookie(cookieName) {
      let domain = document.location.hostname.replace(/^[^.]+/g, "");
      let expires = "expires=Thu, 01 Jan 1970 00:00:00 GMT"
      document.cookie = cookieName + "='';" + expires + ";domain="+domain+";path=/;";
      return true;
  }

}


