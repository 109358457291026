import { View } from 'pacto';
import { AccountData } from "../../account/service/AccountData";
import { AccountHelper } from '../../account/service/AccountHelper';
import { PianoHelper } from "../../account/service/PianoHelper";
import { ConsoleDebug } from '../../../_utils/ConsoleDebug';
import Micromodal from 'micromodal';

const ID_NAVIGATION_ACCOUNT = 'navigation-account';
const CLASS_MODAL_OPEN_CLASS = 'modal--is-open';
const SELECTOR_MODAL = '.modal';
const CTXT_KEY_EVENT_REGISTER_SUCCESS = 'event:account_register_successfully';

export class Register extends View {

  constructor(options) {
    super(options);

    this.logInstance = new ConsoleDebug('debug-account', 'Register');
    this.logInstance.log('core/login/views/Register.js constructor');

    this.accountData = new AccountData(this.context);
    this.pianoHelper = new PianoHelper(this.context);

    const accountEl = document.querySelector('#' + ID_NAVIGATION_ACCOUNT);
    if ( accountEl !== null ) {
      options.el = accountEl;
      this.accountHelper = new AccountHelper(options, this);
    }

    // Exception for Studi+ - Hide Modal and reload page after successful registration
    if (window.location.pathname.includes("/angebote/studiabo")) {
      this.logInstance.log("Studi-Plus: Hide Modal after successful registration");
      this.context.on(CTXT_KEY_EVENT_REGISTER_SUCCESS, (event) => {
        this.logInstance.log('Auf Event \'' + CTXT_KEY_EVENT_REGISTER_SUCCESS + '\' reagiert, this.hideRegisterModal() aufrufen', event)
        this.hideRegisterModal.call(this);
      });
    }
  }

  render() {
    this.logInstance.log('render()');

    this.el.querySelector('form')?.addEventListener('submit', this.accountHelper.registerSubmit);

    // handle the explicit 'loginRequired' Piano callback
    window.tp = window.tp || [];
    window.tp.push(["addHandler", "loginRequired", this.pianoHandlerLoginRequired.bind(this)]);

    return this;
  }

  /**
   * handle the explicit 'loginRequired' Piano callback
   *
   * @param pianoCheckoutParams The value named params in this example contains data required for continuing the checkout flow
   * after user authentication. It could be stored as a local variable during runtime if the user never leaves
   * the page during authentication. If the user has to be redirected to another page for authentication,
   * then the step in the checkout, i.e. the params object, could be stored as a cookie.
   * https://docs.piano.io/piano-id-auth-sync/?paragraphId=0a98b93b4c30ea1
   */
  async pianoHandlerLoginRequired(pianoCheckoutParams) {
    this.logInstance.log('Piano callback \'loginRequired\' triggered, arg params', pianoCheckoutParams);

    if ( typeof pianoCheckoutParams !== "undefined" ) {

      // Sets the cookie with the current state of the user
      this.pianoHelper.savePianoParams(pianoCheckoutParams);

      // When logged in, call startCheckout directly with pianoParams
      if ( await this.accountData.isLoggedIn() ) {
        this.pianoHelper.startCheckout(pianoCheckoutParams);
      }
    }
    else
    {
      this.logInstance.log('pianoCheckoutParams is undefined, don\'t call savePianoParams() and startCheckout()');
    }

    // If not logged in, then show the register form
    if ( !await this.accountData.isLoggedIn() ) {
      this.showRegisterModal.call(this);

      // when event registration successful triggered hide modal
      this.context.on(CTXT_KEY_EVENT_REGISTER_SUCCESS, (event) => {
        this.logInstance.log('Auf Event \'' + CTXT_KEY_EVENT_REGISTER_SUCCESS + '\' reagiert, this.hideRegisterModal() aufrufen', event)
        this.hideRegisterModal.call(this);
      });
    }

    // this will prevent the Piano error screen from displaying
    return false;
  }

  /**
   * show modal "core-register" with register form
   */
  showRegisterModal () {
    this.logInstance.log('showRegisterModal() called');

    const registerModalIntro = this.el.querySelector('.core-register__intro');

    if (registerModalIntro){
      this.logInstance.log('Hide core-register Intro-Text');
      registerModalIntro.style.display = 'none';
    }

    const modalId = this.el.querySelector(SELECTOR_MODAL)?.getAttribute('id');
    if ( modalId !== null ) {
      this.logInstance.log('Show modal #' + modalId);
      Micromodal.show(modalId, { openClass: CLASS_MODAL_OPEN_CLASS});
    } else {
      this.logInstance.warn('No modal found with selector \'' + SELECTOR_MODAL + '\'.' +
        'Actually, the login form is rendered on every page, this case must not occur.');
    }
  }

  hideRegisterModal () {
    this.logInstance.log('hideRegisterModal() called');

    const modalId = this.el.querySelector(SELECTOR_MODAL)?.getAttribute('id');
    if ( modalId !== null ) {
      this.logInstance.log('Hide modal #' + modalId);
      Micromodal.close(modalId, { openClass: CLASS_MODAL_OPEN_CLASS});
    } else {
      this.logInstance.warn('No modal found with selector \'' + SELECTOR_MODAL + '\'.' +
        'Actually, the login form is rendered on every page, this case must not occur.');
    }
  }
}
