import { View } from 'pacto';
import { AccountHelper } from '../../account/service/AccountHelper';
import { ConsoleDebug } from '../../../_utils/ConsoleDebug';

const ID_NAVIGATION_ACCOUNT = 'navigation-account';

export class Login extends View {

  constructor(options) {
    super(options);

    this.logInstance = new ConsoleDebug('debug-account', 'Login');
    this.logInstance.log('core/login/views/Login.js constructor');

    const accountEl = document.querySelector('#' + ID_NAVIGATION_ACCOUNT);
    if ( accountEl !== null ) {
      options.el = accountEl;
      this.accountHelper = new AccountHelper(options, this);
    }
  }

  render() {
    this.logInstance.log('render()');

    this.el.querySelector('form')?.addEventListener('submit', this.accountHelper.loginSubmit);

    return this;
  }
}
