import { View } from 'pacto';
import { ConsoleDebug } from '../../../../_utils/ConsoleDebug';
import {Environment} from "../../../../_utils/Environment";
import {RedfactHelper} from "../../../../_utils/RedfactHelper";

//const CLASS_PAYWALL_HIDDEN = 'paywall--hidden';
//const ERROR_MESSAGE = 'Es ist ein technisches Problem aufgetreten. Bitte versuchen Sie es später noch einmal. Der Webmaster wurde informiert.';

export class Checkout extends View {

  constructor(options) {
    super(options);

    this.logInstance = new ConsoleDebug('debug-account', 'Checkout');
    this.logInstance.log('common/pianopay/checkout/views/Checkout.js constructor');

    this.env = new Environment();
    this.redfactHelper = new RedfactHelper();

    this.sendCheckoutCompleteToRedfact = this.sendCheckoutCompleteToRedfact.bind(this);
  }

  /**
   * @param checkoutParams - Piano checkout params e.g. {"parentOuterHeight":"1175","logType":"offerShow","browserId":"m4b97iq0i0ylptuw","userProvider":"piano_id_lite","widget":"offer","pianoIdUrl":"https://sandbox.piano.io/id/","templateId":"OT5P4XLHF5KB","parentDualScreenLeft":"-1920","experienceActionId":"showOfferGWSLXRY712R86","customVariables":"{}","experienceId":"EXVRVBF1UHIF","containerSelector":"#paywall-test, #piano-static-paywall","offerType":"purchase","hasLoginRequiredCallback":"true","userState":"registered","showCloseButton":"false","displayMode":"inline","url":"https://xmedias3.mannheimer-morgen.de/index.php?artikel=-tobitestkategorie-tobias-test-artikel-&arid=1682473&puid=3&pageid=2490","initMode":"context","parentDualScreenTop":"25","parentWidth":"1920","userToken":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJyZWRGQUNUIiwiaXNzIjoiaHR0cHM6Ly93d3cubWFubmhlaW1lci1tb3JnZW4uZGUiLCJlbWFpbCI6InQuZmVucmljaEBoYWFzLW1lZGllbmdydXBwZS5kZSIsInN1YiI6IjIwMTU4NSIsImZpcnN0X25hbWUiOiJUb2JpYXMiLCJsYXN0X25hbWUiOiJGZW5yaWNoIiwianRpIjoiNzNiY2ExZmNjNWQ0NjM2ZTJkOGZiZTYxODE1ZTQ1Njg2MjVjOGMxY2U4NmRkY2I3Njc1NmVhZWJhODc2ZjZlNSIsInNhbHV0YXRpb24iOiJbXCJEaXZlcnNcIl0iLCJzdWJzY3JpcHRpb25MZXZlbCI6IltcImludGVybmFsVHJhZmZpY1wiXSJ9.4chKkyMs_QPBSu84hAJRmE1Blb3Wv5QoE7Wu9yhWi8c","requestUserAuthForLinkedTerm":"true","checkoutFlowId":"CF732C8H5091","parentHeight":"658","iframeId":"offer-0-4GQcJ","hideCompletedFields":"true","_qh":"03b4156fba","width":null,"offerId":"OF2QX3GC9T4N","formNameByTermId":"{}","customCookies":"{}","aid":"OwRtGt54su","initTime":"6273.60000000149","reactivateSubscriptionId":"","upgradeSubscriptionId":"","termId":"TMH3ZI50HC8P","affiliateState":{"issuerId":"https://www.piano.io","premium":"false","redemptionCandidateItemId":null,"creditStates":[],"userId":"201585","targetGroups":{"l1":"target","l2":null}},"type":"payment","closeOnLogout":true}
   */
  render(checkoutParams) {

    // handle the explicit 'checkoutComplete' Piano callback
    // https://docs.piano.io/callbacks/#completeevent
    window.tp = window.tp || [];
    window.tp.push(["addHandler", "checkoutComplete", this.pianoCheckoutComplete.bind(this)]);

    return this;
	}

  /**
	 * @param conversion e.g. {"rid":"RMBGB9X","chargeAmount":1,"chargeCurrency":"EUR","termConversionId":"TCGUORQDV7WI","paymentId":"UP9G04ZFHG4G","termId":"TMH3ZI50HC8P","startedAt":"2024-12-06T12:18:22.000+00:00","expires":1736225999,"uid":"201585","email":"t.fenrich@haas-mediengruppe.de","token_list":"{jax}Fvx0QNmtX3ze_djbOiBNvlwEhZRTb-1E2TWAC37h00AQbu9T3-r3w9leKKJBqSGj6WbuOteU3SxZfCQuvTC0YvadUOwSnfMAq29YWL2dNVJ4AHiCZhwZcCUPqSuRywtvR-OuSdz5dQcdHsKTscF0jL1Q-HYNpdRADkHFk7_DQx0","cookie_domain":".mannheimer-morgen.de","promotionId":null,"subscriptionId":"RCC8VM93NKJR","sharedAccounts":[],"type":"payment","offerId":"OF2QX3GC9T4N"}
   */
  pianoCheckoutComplete(conversion) {
    this.logInstance.log('Piano callback \'checkoutComplete\' triggered');
    this.sendCheckoutCompleteToRedfact(conversion);
  }

  /**
   * The redFACT REST service "/REST/payment/pianocheckoutcomplete" checks whether access to the piano resource exists
   * and then creates a redFACT "Bestellung (Zugriffsrecht)"
	 *
	 * @param conversion e.g. {"rid":"RMBGB9X","chargeAmount":1,"chargeCurrency":"EUR","termConversionId":"TCGUORQDV7WI","paymentId":"UP9G04ZFHG4G","termId":"TMH3ZI50HC8P","startedAt":"2024-12-06T12:18:22.000+00:00","expires":1736225999,"uid":"201585","email":"t.fenrich@haas-mediengruppe.de","token_list":"{jax}Fvx0QNmtX3ze_djbOiBNvlwEhZRTb-1E2TWAC37h00AQbu9T3-r3w9leKKJBqSGj6WbuOteU3SxZfCQuvTC0YvadUOwSnfMAq29YWL2dNVJ4AHiCZhwZcCUPqSuRywtvR-OuSdz5dQcdHsKTscF0jL1Q-HYNpdRADkHFk7_DQx0","cookie_domain":".mannheimer-morgen.de","promotionId":null,"subscriptionId":"RCC8VM93NKJR","sharedAccounts":[],"type":"payment","offerId":"OF2QX3GC9T4N"}
   */
  sendCheckoutCompleteToRedfact (conversion) {
    this.logInstance.log('postPianoCheckoutComplete() called');

    let fetchInput, formData, fetchInit;
    try
    {
      fetchInput = this.redfactHelper.getRestEndpointPianoCheckoutComplete();
      fetchInput += `?rand=${Math.random()}`;

      formData = new FormData();
      formData.append('puid', nfyPu);
      formData.append('pageid', nfyJsonParams[`pageid`]);
      formData.append('conversion', JSON.stringify(conversion));
      if ( Number.isInteger(Number.parseInt(window.nfyJsonParams[`arid`])) ) {
        formData.append('dsid', nfyJsonParams[`arid`]);
        formData.append('shortcode', 'ar');
      }

      fetchInit = { method: 'POST', body: formData, credentials: 'include' };

      // Wenn Fractal Web UI Server, dann via GET
      if (window.location.host.match(/^localhost/) !== null) {
        if (this.el.getAttribute('data-endpoint') !== null
          && this.el.getAttribute('data-endpoint') !== '') {
          fetchInput = this.el.getAttribute('data-endpoint');
          fetchInput += `?rand=${Math.random()}`;
          fetchInit = {};
        } else {
          throw new Error('add attribute data-endpoint to this.el => ', this.el);
        }
      }
    } catch (e) {
      this.logInstance.error(e.message);
      return false;
    }

    return window.fetch(fetchInput, fetchInit)
      .then((response) => response.json())
      .then((data) => {
        // data e.g. => {"success":true}
        if ( typeof data.success === "boolean" && data.success ) {
          // reload will be executed by piano
        } else {
          this.logInstance.warn('sendCheckoutCompleteToRedfact() failed response', data);
        }
      })
      .catch((error) => {
        this.logInstance.warn('Error sendCheckoutCompleteToRedfact(): ' + error);
      });
  }
}
