import { View } from 'pacto';
import { ConsoleDebug } from '../../../../_utils/ConsoleDebug';
import {Environment} from "../../../../_utils/Environment";

const CTXT_KEY_EVENT_LOGIN_SUCCESS = 'event:account_login_successfully';
const CTXT_KEY_PIANO_PAYWALL_OFFER_SHOWN = 'piano:paywall_offer_shown';

export class Paywall extends View {

  constructor(options) {
    super(options);

    this.env = new Environment();
    this.logInstance = new ConsoleDebug('debug-account', 'Paywall');
    this.logInstance.log('common/pianopay/paywall/views/Paywall.js constructor');
    this.preventReload = false;
  }

  render() {

    // handle the explicit 'loginRequired' Piano callback and prevent reload in this case
    window.tp = window.tp || [];
    window.tp.push(["addHandler", "loginRequired", (pianoCheckoutParams) => {
      this.logInstance.log('Piano callback \'loginRequired\' triggered, set preventReload=true');
      this.preventReload = true;
    }]);

    // Listen for event "Login successfully" and reload for the redFACT Payment Cookie
    this.context.on(CTXT_KEY_EVENT_LOGIN_SUCCESS, (event) => {
      if ( !this.preventReload ) {
        this.logInstance.log('Auf Event \'' + CTXT_KEY_EVENT_LOGIN_SUCCESS + '\' reagiert, do reload', event);
        window.location.reload();
      }
    });

    if ( !this.context.values.has(CTXT_KEY_PIANO_PAYWALL_OFFER_SHOWN) ) {
      this.logInstance.log('context.values.has(' + CTXT_KEY_PIANO_PAYWALL_OFFER_SHOWN + ') is false, '
        + '\'showOffer\' for paywall has not been shown yet, then to explicit window.tp.experience.execute()');
      window.tp.experience?.execute();
    }

    return this;
	}
}
