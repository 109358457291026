import { Initialize } from 'pacto';
import { Login as View } from '../views/Login';


export class Action extends Initialize {
  get settings() {
    return {
      selector: '.core-login',
      namespace: 'loginaccount:views',
      view: View,
    };
  }
}
