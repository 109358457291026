import { View } from 'pacto';
import {AccountData} from "../../../../core/account/service/AccountData";
import {AccountHelper} from "../../../../core/account/service/AccountHelper";
import {ConsoleDebug} from "../../../../_utils/ConsoleDebug";
import {PianoHelper} from "../../../../core/account/service/PianoHelper";

const ID_NAVIGATION_ACCOUNT = 'navigation-account';
const CLASS_LOGOUT_CONTAINER = 'account-bluebox--logout';
const CLASS_LOGIN_BUTTON = 'common-pianopay-selfservice__link';
const CTXT_KEY_EVENT_PIANO_JWT_PUSHED = 'event:account_piano_jwt_pushed';

export class Selfservice extends View {

  constructor(options) {
    super(options);

    this.logInstance = new ConsoleDebug('debug-account', 'Selfservice');
    this.logInstance.log('core/login/views/Selfservice.js constructor');

    this.accountData = new AccountData(this.context);
    this.pianoHelper = new PianoHelper(this.context);

    const accountEl = document.querySelector('#' + ID_NAVIGATION_ACCOUNT);
    if ( accountEl !== null ) {
      options.el = accountEl;
      this.accountHelper = new AccountHelper(options, this);
    }
  }

  render() {
    this.logInstance.log('render()');

    this.handlePianoSelfservice();

    return this;
  }

  async handlePianoSelfservice () {

    // first try ... the user may not be logged in yet for various reasons
    if ( await this.isAuthSyncReady() )
    {
      this.insertLogoutContainer();
      this.showPianoSelfservice();
    }
    else
    {
      this.insertLoginContainer();

      // if showPianoSelfservice() failed, then start context-eventListener on 'event:account_piano_jwt_pushed' and call again showPianoSelfservice()
      this.logInstance.log('Start context-eventListener on \'' + CTXT_KEY_EVENT_PIANO_JWT_PUSHED + '\'');
      this.context.on(CTXT_KEY_EVENT_PIANO_JWT_PUSHED, async (event) => {
        this.logInstance.log('Context-event \'' + CTXT_KEY_EVENT_PIANO_JWT_PUSHED + '\' triggered, call showPianoSelfservice() ');

        if ( await this.isAuthSyncReady() )
        {
          this.el.innerHTML = '';
          this.insertLogoutContainer();
          this.showPianoSelfservice();
        }
      });
    }
  }

  async isAuthSyncReady () {
    this.logInstance.log('isAuthSyncReady() called');

    if (typeof window.tp.myaccount !== "object") {
      this.logInstance.warn('window.tp.myaccount is not a object');
      return false;
    }

    if ( !window.tp.pianoIdLite.isUserValid() ) {
      this.logInstance.warn('user is not valid in pianoIdLite');
      return false;
    }

    if ( ! await this.accountData.isLoggedIn() ) {
      this.logInstance.warn('user is not logged in in accountData');
      return false;
    }

    return true;
  }

  showPianoSelfservice (event) {
    this.logInstance.log('showPianoSelfservice() called');

    const containerSelector = this.el.getAttribute('id');
    if ( containerSelector !== null )
    {
      window.tp = window["tp"] || [];
      window.tp.myaccount.show({
          displayMode: "inline",
          containerSelector: '#' + containerSelector
      });
    }
  }

  insertLoginContainer () {
    this.logInstance.log('insertLoginContainer() called');

    const loginContainer =
      `<div class="common-pianopay-selfservice__login">` +
        `<p>Bereits registriert? ` +
          `<a class="` + CLASS_LOGIN_BUTTON + `" title="Mit Ihrem Konto anmelden" data-micromodal-trigger="core-login">Hier anmelden.</a>`+
        `</p>` +
      `</div>`;

    const range = document.createRange();
    range.selectNode(document.querySelector('body'));
    const fragment = range.createContextualFragment(loginContainer);

    this.el.append(fragment);
  }

  insertLogoutContainer () {
    this.logInstance.log('insertLogoutContainer() called');

    if ( document.querySelector('.' + CLASS_LOGOUT_CONTAINER) === null ) {
      const logoutContainer =
        `<div class="account-bluebox `+ CLASS_LOGOUT_CONTAINER + `">` +
          `<div>` +
            `<div class="bluebox-wrapper">` +
              `<button class="account-bluebox__link" title="Hier abmelden" type="button">Logout</button>`+
            `</div>` +
          `</div>` +
        `</div>`;

      // DocumentFragment auf Basis des HTML-Codes erstellen (schneller als viele DOM-Änderungen)
      const range = document.createRange();
      range.selectNode(document.querySelector('body'));
      const fragment = range.createContextualFragment(logoutContainer);

      // DocumentFragment im DOM einfügen
      const listTopicDiv = this.el.closest('.list-topic');
      listTopicDiv.parentNode.insertBefore(fragment, listTopicDiv);

      // Auf Logout-Klick reagieren
      listTopicDiv.parentNode.querySelector('.' + CLASS_LOGOUT_CONTAINER + ' button')?.addEventListener('click', this.accountHelper.logoutClick);
    }
  }
}
