import { Initialize } from 'pacto';
import { Selfservice as View } from '../views/Selfservice';
import {PactoHelper} from "../../../../_utils/PactoHelper";
import {Environment} from "../../../../_utils/Environment";

const SELECTOR_SELFSERVICE = '.common-pianopay-selfservice';

export class Action extends Initialize {
  get settings() {
    return {
      selector: SELECTOR_SELFSERVICE,
      namespace: 'pianoselfservice:views',
      view: View,
    };
  }

  run() {
    const env = new Environment();
    if ( env.isTestDatabaseEnvironment() || env.isSelfservicePage() ) {
      (new PactoHelper()).waitForElm(SELECTOR_SELFSERVICE).then(() => {
        super.run();
      });
    }
  }
}
