import { Environment } from "../../../_utils/Environment";
import { ConsoleDebug } from '../../../_utils/ConsoleDebug';
import { PianoHelper } from "../../account/service/PianoHelper";

const toolToken = 'tool_1stparty_piano';
let pianoLoaded = false;

export class Piano {

  /**
   * @param {Context} context ist erforderlich um ihn an die Account-Daten Instanz weiterzugeben
   */
  constructor(context) {
    this.context = context;
    this.env = new Environment();
    this.logInstance = new ConsoleDebug('#debug-piano', 'Piano');
    this.pianoHelper = new PianoHelper(this.context);
  }

  load() {
    this.logInstance.log('piano.load() called');

    let pianoConfig = this.pianoHelper.pianoConfigObj;

    if (pianoConfig.pianoAppId !== '') {
      this.logInstance.log('pianoAppId = ' + pianoConfig.pianoAppId);
      let url = "https://" + pianoConfig.staticDomain + "/api/tinypass.min.js"

      let pianoScriptTag = document.createElement('script');
      pianoScriptTag.type = "text/javascript";
      pianoScriptTag.src = url;
      pianoScriptTag.async = true;
      pianoScriptTag.onload = () => {
        window.PianoESPConfig = {
          id: pianoConfig.espConfigId
        }
        tp = window["tp"] || [];

        function onCheckoutClose(event) {
          if (event && (event.state == "checkoutCompleted" || event.state == "alreadyHasAccess")) {
            if (window.location.pathname.includes("/angebote/")) {
              window.location.href = window.location.origin;
            } else {
              window.location.reload();
            }
          }
        }

        tp.push(["setAid", pianoConfig.pianoAppId]);
        tp.push(["setUseTinypassAccounts", false]);
        tp.push(["setUsePianoIdLiteUserProvider", true]);

        if (!this.env.isTestDatabaseEnvironment()) {
          tp.push(["setCxenseSiteId", pianoConfig.cxenseSiteId]);
        }

        /* checkout related events */
        tp.push(["addHandler", "checkoutClose", onCheckoutClose]);

        tp.push(["init", function () {
          tp.push(["setComposerHost", `https://${pianoConfig.composerHost}`]);
          tp.push(["setPianoIdUrl", `https://${pianoConfig.pianoIdUrl}`]);
          tp.push(["setEndpoint", `https://${pianoConfig.endpoint}`]);
          tp.push(["setStaticDomain", `https://${pianoConfig.staticDomain}`]);

          this.pianoHelper.handleAuthSync();
        }.bind(this)]);
      }

      //Piano-Script should be first script in head
      let firstScriptTag = document.getElementsByTagName("script")[0];

      if (firstScriptTag) {
        firstScriptTag.parentNode.insertBefore(pianoScriptTag, firstScriptTag);
        pianoLoaded = true;
      } else {
        document.head.appendChild(pianoScriptTag);
      }
      pianoLoaded = true;
      this.logInstance.log('piano script appended')
      this.logInstance.log(pianoScriptTag)
    } else {
      this.logInstance.log('environment or pianoAppId not set');
      this.logInstance.log('failed to load piano script');
    }
  }

  destroy() {
    pianoLoaded = false;
  }

  getToolToken() {
    return toolToken;
  }

  isLoaded() {
    return pianoLoaded;
  }
}
